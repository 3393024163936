import { css } from "styled-components";
import { Cores } from "../ativos/cores/Inicio";

export const Tipografia = ({ fonte, peso, tamanho, altura }) => css`
  font-family: ${fonte ? fonte : "Fira Sans"};
  font-weight: ${peso ? peso : "400"};
  font-size: ${tamanho ? tamanho : "16px"};
  line-height: ${altura ? altura : "24px"};
`;

export const FlexCenter100 = () => css`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FlexCenter = (altura) => css`
  height: ${altura};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PaddingHorizontal = (esquerda, direita) => css`
  padding-left: ${esquerda ? esquerda + "px" : "10px"};
  padding-right: ${direita ? direita + "px" : "10px"};
`;

export const MarginHorizontal = (esquerda, direita) => css`
  margin-left: ${esquerda ? esquerda + "px" : "10px"};
  margin-right: ${direita ? direita + "px" : "10px"};
`;

export const LimparMargemPadding = () => css`
  margin: 0;
  padding: 0;
`;

export const IconeCartao = () => css`
  border-radius: 8px;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const Ancora = () => css`
  color: ${Cores.laranjaPrimario};
  text-decoration: none;
  display: inline-block;
  position: relative;
  transition: all ease .2s;

  &::before{
      transition: all ease .1s;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 0%;
      height: 2px;
      background-color: ${Cores.laranjaSecundario};
  }

  &:hover{
      color: ${Cores.laranjaSecundario};
      &::before{
          width: 100%;
      }
  }
`

export const EstilosDeFormulario = () => css`
  border: 1px solid ${Cores.cinza01};
  border-radius: 4px;
  height: 58px;
  text-indent: 16px;
  width: 100%;

  &:focus{
      border-color: ${Cores.laranjaPrimario};
      outline: none;
  }

  &::placeholder {
      color: ${Cores.cinza02};
  }
`

export const DistribuirLargura = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const MixinRadio = css`
  width: ${props => props.width || 20}px;
  height: ${props => props.height || 20}px;
  background-color: ${props => props.background || 'white'};
  border: 2px solid ${Cores.cinza01};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background-color: ${props => props.background || 'white'};
    &:after{
        display: block;
        content: '';
        width: ${props => (props.width || 20) / 2}px;
        height: ${props => (props.height || 20) / 2}px;
        background-color: ${Cores.laranjaSecundario};
        border-radius: 50%;
    }
  }
`;