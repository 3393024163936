import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));
const Pergunta = React.lazy(() => import("../Pergunta"));
const Segmentos = React.lazy(() => import("../Segmentos"));

const Ajuda = [
  {
    path: `/ajuda/`,
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: `/ajuda/:tipo/`,
    exact: true,
    component: Pergunta,
    private: true
  },
  {
    path: `/ajuda/segmentos/:tipo/`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/ajuda/segmentos/:tipo/:segmento`,
    exact: true,
    component: Pergunta,
    private: true
  },
];

export default Ajuda;
