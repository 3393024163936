import React from 'react';

const NovoPlano = [
    {
        path: `/admin/novo-plano`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
]

export default NovoPlano