import iconeCarregando from "./icone-carregando.webp";
import iconeExemplo from "./icone-exemplo.svg";
import logo from "./logo-divulgasula-color.png";
import medicos from "./medicos.svg";
import novo from "./novo.svg";

export const mapaImagens = {
  logo,
  novo,
  iconeCarregando,
  iconeExemplo,
  medicos
};
