import axios from 'axios';

const IsAdmin = async (tipoUsuario, token) => {

  // Verifica se as informações de usuário e token foram passadas por parâmetro
  if (!tipoUsuario || !token) {
    tipoUsuario = sessionStorage.getItem("ds-user-t");
    token = sessionStorage.getItem("_dsat");
  }

  // Verifica se o tipo de usuário é diferente de UG
  if (tipoUsuario !== "UG") {
    return { status: 200, isAdmin: false, nivelUsuario: "USUARIO" };
  }

  // Verifica se as informações de isAdmin e nivelUsuario já estão armazenadas na sessionStorage
  if (sessionStorage.getItem("_ads") || sessionStorage.getItem("_utds")) {
    return {
      status: 200,
      isAdmin: sessionStorage.getItem("_ads") === "true",
      nivelUsuario: sessionStorage.getItem("_utds"),
    };
  }

  // Informações não encontradas no cache, faz a requisição para a API
  const response = await axios.post(process.env.REACT_APP_BFF_ENDPOINT, {
    query: `{nivelUsuario}`,
  }, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "tipo-usuario": tipoUsuario,
    },
  }).catch(async (error) => {
    if ([401, 403, 400].includes(error.response.status)) {
      return {
        status: error.response.status,
        isAdmin: false,
        message: "Ops! Não foi possível autenticar. Verifique suas credenciais ou permissões de acesso!",
      };
    } else {
      return {
        status: error.response.status,
        isAdmin: false,
        message: "Ops! O sistema se encontra indisponível no momento. Tente novamente mais tarde.",
      };
    }
  });

  // Armazena as informações na sessionStorage
  sessionStorage.setItem("_utds", response.data.data.nivelUsuario.split("_")[1]);

  const result = {
    status: response.status,
    isAdmin: response.status === 200 && response.data.data.nivelUsuario.split("_")[1] === "GERAL",
    nivelUsuario: sessionStorage.getItem("_utds"),
    message: "O sistema se encontra indisponível no momento. Tente novamente mais tarde."
  };

  return result;
};

export default IsAdmin;
