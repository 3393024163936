import React from 'react';

const Faq = [
    {
        path: `/admin/faq`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
    {
        path: `/admin/faq/editar-pergunta`,
        exact: true,
        component: React.lazy(() =>
            import("../Editar")
        ),
        private: true
    },
    {
        path: `/admin/faq/criar-pergunta`,
        exact: true,
        component: React.lazy(() =>
            import("../NovaFaq")
        ),
        private: true
    },
]

export default Faq