export const Cores = {
  branco: "#ffffff",
  preto: "#000000",
  laranjaPrimario: "#FF5000",
  laranjaSecundario: "#FF8C00",
  laranjaHover: "#B23800",
  azulPrimario: "#001E64",
  azulSecundario: "#146EFA",
  turquesaSecundario: "#00E1B9",
  roxoSecundario: "#9632FA",
  verde: "#00E0BA",
  verdeBandeira: "#00861F",
  amarelo: "#ed8200",
  vermelho: "#D30E00",
  bordo: "#81001f",
  cinza01: "#D2D2D2",
  cinza02: "#AAAAAA",
  cinza03: "#828282",
  cinza04: "#5A5A5A",
  cinza05: "#323232",
  cinza06: "#E5E5E5",
  cinza07: "#D1D1D1",
  cinza08: "#595959",
  cinza09: "#EFEFEF",
  fundo: "#f8f8f8",
  laranjaTransparente: "#F502",
  verdeTransparente: "#0EB2",
  roxoTransparente: "#93F2",
  azulTransparente: "#16F2",
  amareloTransparente: "#F802"
};
