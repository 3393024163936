import IsAdmin from './IsAdmin';
import Token from './Token';

const Autenticar = async (chaveAutenticacao, tipoUsuario) => {
  const chave = {"chave-autenticacao": chaveAutenticacao};
  try {
    const data = await Token(chave, tipoUsuario);

    if (data.status !== 201) {
      return data;
    }

    const { accessToken } = data.data;

    const checkAdmin = await IsAdmin(tipoUsuario, accessToken);

    if (checkAdmin.status !== 200) {
      return checkAdmin;
    }

    sessionStorage.setItem("_ads", checkAdmin.isAdmin);
    sessionStorage.setItem("_dsat", accessToken);
    sessionStorage.setItem("ds-user-t", tipoUsuario);
    sessionStorage.setItem("ds-login-ts", Date.now());
    sessionStorage.setItem("ds-login-c", chaveAutenticacao);


    return { status: 201, token: accessToken };
  } catch (error) {
    throw new Error("Internal Server Error");
  }
};

export default Autenticar;