import React, { Suspense, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RotasAdmin from "./pacotes/admin/rotas/RotasAdmin";
import RotasCliente from "./pacotes/cliente/RotasCliente";
import Carregador from "./pacotes/comum/atomos/Carregador";
import Contextos, { dados } from "./pacotes/comum/contextos/Contextos";
import Sessoes from "./pacotes/comum/servicos/dominios/Sessoes/Sessoes";


const AcessoCli = React.lazy(() => import("./pacotes/cliente/acesso/Acesso"));
const AcessoAdm = React.lazy(() => import("./pacotes/admin/acesso/Acesso"));
const EmConstrucao = React.lazy(() => import("./pacotes/cliente/emConstrucao/Inicio"));

const routeList = [
  {
    path: `/login/:userType`,
    exact: true,
    component: AcessoCli,
    private: false
  },
  {
    path: `/dsadm-login`,
    exact: true,
    component: AcessoAdm,
    private: false
  },
  {
    path: `/em-construcao`,
    exact: true,
    component: EmConstrucao,
    private: false
  }
];

const sessao = new Sessoes();
const authenticated = sessionStorage.getItem(`_dsat`);
const basename =
  authenticated && sessao.UserType !== "C" ? `/${sessao.UserType}` : "/";

if (authenticated) {
  if (sessao.UserType !== "UG") {
    routeList.unshift(...RotasCliente);
  } else {
    routeList.unshift(...RotasAdmin);
  }
}

const Routes = () => {
  const [fabrica, definirFabrica] = useState(dados);

  return (
    <BrowserRouter basename={basename}>
      <Contextos.Provider value={{ fabrica, definirFabrica }}>
        <Suspense fallback={<Carregador />}>
          <Switch>
            {routeList.map((route, key) => {
              return <Route key={key} {...route} />;
            })}
            <Redirect to={authenticated ? "/" : "/login/ "} />
          </Switch>
        </Suspense>
      </Contextos.Provider>
    </BrowserRouter>
  );
};

export default Routes;
