import React from 'react';

const OiSulamerica = [
    {
        path: `/admin/oi-sulamerica`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
]

export default OiSulamerica