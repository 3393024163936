import React from 'react';

const Precificacao = [
    {
        path: `/admin/precificacao`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
]

export default Precificacao