import axios from 'axios';

const Token = async (corpo, tipoUsuario) => {

  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_AUTH_ENDPOINT,
      data: corpo,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "tipo-usuario": tipoUsuario
      }
    });
    return response;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        message: "Ops! Não foi possível autenticar. Verifique suas credenciais ou permissões de acesso!"
      };
    } else {
      return {
        status: 500,
        message: "Ops! Ocorreu um erro na autenticação."
      };
    }
  }
};

export default Token;
