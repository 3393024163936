import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));

const Analytics = [
  {
    path: ["/", "/admin/analytics"],
    exact: true,
    component: Inicio,
    private: true
  }
];

export default Analytics;
