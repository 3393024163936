import forge from 'node-forge';

export default async function criptografarCredenciais(credenciais, chavePublica) {

    sessionStorage.setItem("ds-login-p", credenciais.senha);
    sessionStorage.setItem("ds-login-u", credenciais.usuario);
    sessionStorage.setItem("ds-login-g", credenciais.captcha);
    sessionStorage.setItem("ds-user-t", credenciais.tipoUsuario);

    const credencial = {
        username: credenciais.usuario,
        password: credenciais.senha,
        captcha: credenciais.captcha
    }

    const publicKey = forge.pki.publicKeyFromPem(chavePublica);
    const credentialsString = JSON.stringify(credencial);


    var encrypted = publicKey.encrypt(credentialsString, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: {
            md: forge.md.sha1.create()
        }
    })

    return forge.util.encode64(encrypted);
}

