import styled, { keyframes } from "styled-components";
import { Cores } from "../../ativos/cores/Inicio";
import * as Mx from "../../estilos/Mixins";

export const Botao = styled.button`
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: "Fira Sans", sans-serif;
    transition: all ease .4s;
    line-height: 2.75;

    &.pequeno{
        background-color: ${Cores.laranjaSecundario};
        color: ${Cores.branco};
        border-radius: 8px;
        display: inline-block;
        transition: all ease .4s;
        padding: 2px 16px 2px 16px;
        width: auto;
        font-size: 0.9rem;

        &:hover{
            background-color: ${Cores.laranjaHover};
        }
    }

    &.flutuar-direita{
        float: right;
    }

    &.desativar{
        background-color: transparent;
        color: ${Cores.cinza04};

        &:hover{
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        }
    }

    &.ativar{
        background-color: ${Cores.laranjaPrimario};
        color: ${Cores.branco};

        &:hover{
            background-color: ${Cores.laranjaHover};
        }
    }

    &:disabled{
        background-color: ${Cores.cinza01};
        cursor: default;

        &:hover{
            background-color: ${Cores.cinza02};
        }
    }
`

export const AreaDeTexto = styled.textarea`
    ${Mx.EstilosDeFormulario};
    text-indent: 0;
    padding: 15px;
    height: 170px;
`

export const CampoDeFormulario = styled.input`
    &[type=text], &[type=password], &[type=number], &[type=date], &[type=datetime-local], &[type=email], &[type=tel]{
        ${Mx.EstilosDeFormulario};
    }

    &[type=checkbox], &[type=radio]{
        opacity: 0;
        position: relative;
        top: 1px;
        left: -15px;
        width: 14px;
        z-index: 1;
        cursor: pointer;
    }
`

export const CaixaDeMarcacao = styled.span`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transition: all ease .2s;
    position: relative;
    position: relative;
    top: 3px;
    margin-right: -13px;

    &.desmarcado{
        background: transparent;
        border: 1px solid ${Cores.cinza03};
    }

    &.marcado{
        background: ${Cores.laranjaPrimario};
        border: 1px solid transparent;

        &::after, &::before{
            content: '';
            display: block;
            height: 2px;
            background: #fff;
            position: absolute;
        }

        &::after{
            transform: rotate(-55deg);
            width: 12px;
            top: 6px;
            left: 3px;
        }

        &::before{
            transform: rotate(-126deg);
            top: 8px;
            left: 1px;
            width: 5px;
        }
    }
`

export const Rotulo = styled.label`
    font-size: 1rem;
    color: ${Cores.cinza03};
    font-weight: normal;
`

export const Ancora = styled.a`
    ${Mx.Ancora}
`

export const Figura = styled.figure`
    ${Mx.FlexCenter('70px')}

    &.grande{
        img{
            width: 200px
        }
    }
`

const rotacionar = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const Carregando = styled.div`
  svg {
    font-size: 2.4rem !important;
    animation: ${rotacionar} 2s linear infinite;
  }

  color: #ff5000;
  text-align: center;
`;

export const Carregador = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 99999;
  justify-content: center;
  align-items: center;

  img {
    max-width: 50px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
