import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));
const Produtos = React.lazy(() => import("../Produtos"));
const TiposMaterial = React.lazy(() => import("../TiposMaterial"));
const Formatos = React.lazy(() => import("../Formatos"));
const Artefatos = React.lazy(() => import("../Artefatos"));
const EditarProduto = React.lazy(() => import("../EditarProduto"));

const CentralDeMateriais = [
  {
    path: ["/", "/admin/central-de-materiais"],
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: `/admin/central-de-materiais/planos`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/admin/central-de-materiais/tipo`,
    exact: true,
    component: TiposMaterial,
    private: true
  },
  {
    path: `/admin/central-de-materiais/formatos`,
    exact: true,
    component: Formatos,
    private: true
  },
  {
    path: `/admin/central-de-materiais/artefatos`,
    exact: true,
    component: Artefatos,
    private: true
  },
  {
    path: `/admin/central-de-materiais/editarproduto`,
    exact: true,
    component: EditarProduto,
    private: true
  }
];

export default CentralDeMateriais;
