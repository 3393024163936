import Acesso from "./acesso/rotas/Acesso.routes";
import Ajuda from "./ajuda/rotas/Ajuda.routes";
import CriarMaterial from "./criarMaterial/rotas/CriarMaterial.routes";
import EmConstrucao from "./emConstrucao/rotas/EmConstrucao.routes";
import Inicio from "./inicio/rotas/Inicio.routes";
import MateriaisProntos from "./materiaisProntos/rotas/MateriaisProntos.routes";
import MeusMateriais from "./meusMateriais/rotas/MeusMateriais.routes";

const RotasCliente = [
  ...Ajuda,
  ...Inicio,
  ...CriarMaterial,
  ...MateriaisProntos,
  ...MeusMateriais,
  ...Acesso,
  ...EmConstrucao
];

export default RotasCliente;
