import React from "react";
import ReactDOM from "react-dom";
import Rotas from "./Rotas";
import "./pacotes/comum/ativos/estilos/Inicio.scss";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const cacheBuster = process.env.REACT_APP_CACHE_BUSTER;

ReactDOM.render(
  <React.StrictMode>
    <Rotas />
  </React.StrictMode>,
  document.getElementById("root")
);

 // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register();

 // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

if (cacheBuster) {
  // Adiciona a string aleatória como parâmetro de consulta na URL dos arquivos estáticos
  const links = document.getElementsByTagName("link");
  for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const href = link.href;
      if (href.endsWith(".css")) {
        link.href = `${href}?${cacheBuster}`;
      }
  }
    const scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i];
    const src = script.src;
    if (src.endsWith(".js")) {
      script.src = `${src}?${cacheBuster}`;
    }
  }
}