import React from "react";

const NovoMaterial = [
    {
        path: `/admin/novo-material`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
]

export default NovoMaterial
