import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));
const Artefatos = React.lazy(() => import("../../artefatos/Inicio"));

const MeusMateriais = [
  {
    path: `/meus-materiais/`,
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: `/meus-materiais/:tipo`,
    exact: true,
    component: Artefatos,
    private: true
  }
];

export default MeusMateriais;
