import React from "react";

const Componente = React.lazy(() => import("../Inicio"));
const EmConstrucao = [
  {
    path: `/em-construcao`,
    exact: false,
    component: Componente,
    private: false
  }
];

export default EmConstrucao;
