import Analytics from "../analytics/rotas/Analytics.routes";
import CentralDeMateriais from "../centralDeMateriais/rotas/CentralDeMateriais.routes";
import Contador from "../contador/rotas/Contador.routes";
import Faq from "../faq/rotas/Faq.routes";
import Lixeira from "../lixeira/rotas/Lixeira.route";
import NovoMaterial from "../novoMaterial/rotas/NovoMaterial.route";
import NovoPlano from "../novoPlano/rotas/NovoPlano.route";
import OiSulamerica from "../oiSulamerica/rotas/OiSulamerica.route";
import Precificacao from "../precificacao/rotas/Precificacao.route";
import Segmentacao from "../segmentacao/rotas/Segmentacao.route";

const RotasAdmin = [
  ...CentralDeMateriais,
  ...Faq,
  ...NovoMaterial,
  ...NovoPlano,
  ...OiSulamerica,
  ...Lixeira,
  ...Contador,
  ...Segmentacao,
  ...Precificacao,
  ...Analytics
];

export default RotasAdmin;
