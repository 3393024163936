import React from 'react';

const Segmentacao = [
    {
        path: `/admin/segmentacao`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
    {
        path: `/admin/segmentacao/editar-segmento`,
        exact: true,
        component: React.lazy(() =>
            import("../EditarSegmento")
        ),
        private: true
    },
    {
        path: `/admin/segmentacao/novo-segmento`,
        exact: true,
        component: React.lazy(() =>
            import("../NovoSegmento")
        ),
        private: true
    },
    {
        path: `/admin/segmentacao/novo-subsegmento`,
        exact: true,
        component: React.lazy(() =>
            import("../NovoSubSegmento")
        ),
        private: true
    },
    {
        path: `/admin/segmentacao/editar-subsegmento`,
        exact: true,
        component: React.lazy(() =>
            import("../EditarSubSegmento")
        ),
        private: true
    },
]


export default Segmentacao