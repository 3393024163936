import React from "react";

export const dados = {
  codigoFormato: null,
  codigoProduto: [],
  codigoArtefato: [],
  dataCriacaoInicio: null,
  dataCriacaoFim: null
};

const Contextos = React.createContext(dados);
export default Contextos;
