import Autenticar from './Autenticar';
import Encerrar from './Encerrar';
import IsAdmin from './IsAdmin';
import { PUBLIC_KEY } from './chave-publica.js';
import criptografarCredenciais from './criptografarCredenciais';
export default class Sessoes {

  async IsAdmin(tipoUsuario, token) {
    const response = await IsAdmin(tipoUsuario, token);
    return response;
  }

  async Autenticar(credenciais) {
    const tipoUsuario = credenciais.tipoUsuario;
    const credenciaisSemTipo = {...credenciais};
    delete credenciaisSemTipo.tipoUsuario;
    const chaveAutenticacao = await criptografarCredenciais(credenciais, PUBLIC_KEY);
    const chave = {"chave-autenticacao": chaveAutenticacao};
    const response = await Autenticar(chaveAutenticacao, tipoUsuario);
    return response;
  }

  Encerrar = Encerrar;

  UserId() {
    const storedValue = sessionStorage.getItem("ds-login-u");
    const cleanedValue = storedValue ? storedValue.replace(/[^\d]+/g, '') : '';
    return cleanedValue;
  }

  UserType = sessionStorage.getItem("ds-user-t");


  // UserId =
  //   sessionStorage.getItem("ds-user") &&
  //   sessionStorage.getItem("ds-user-t") === "C"
  //     ? sessionStorage.getItem("ds-user").split("-")[0]
  //     : sessionStorage.getItem("ds-user");
}
