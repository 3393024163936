import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));
const Segmentos = React.lazy(() => import("../../segmentos/Inicio"));
const Subsegmentos = React.lazy(() => import("../../subsegmentos/Inicio"));
const Produtos = React.lazy(() => import("../../produtos/Produtos"));
const Artefatos = React.lazy(() => import("../../artefatos/Inicio"));
const PersonalizarArtefato = React.lazy(() => import("../../personalizarArtefato/Inicio"));

const CriarMaterial = [
  {
    path: `/novo-material`,
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: `/novo-material/:formato`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/novo-material/:formato/:segmento`,
    exact: true,
    component: Subsegmentos,
    private: true
  },
  {
    path: `/novo-material/:formato/:segmento/:subsegmento`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/novo-material/:formato/:segmento/:subsegmento/:produto`,
    exact: true,
    component: Artefatos,
    private: true
  },
  {
    path: `/novo-material/:formato/:segmento/:subsegmento/:produto/:artefato`,
    exact: true,
    component: PersonalizarArtefato,
    private: true
  }
];

export default CriarMaterial;
