import React from "react";

const Inicio = React.lazy(() => import("../Inicio"));
const Segmentos = React.lazy(() => import("../../segmentos/Inicio"));
const Subsegmentos = React.lazy(() => import("../../subsegmentos/Inicio"));
const Produtos = React.lazy(() => import("../../produtos/Produtos"));
const Artefatos = React.lazy(() => import("../../artefatos/Inicio"));
const Formatos = React.lazy(() => import("../../formatos/Inicio"));

const MateriaisProntosVideos = [
  {
    path: `/materiais-prontos-videos/tipo=VIDEOS`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/materiais-prontos-videos/tipo=VIDEOS/:segmento`,
    exact: true,
    component: Subsegmentos,
    private: true
  },
  {
    path: `/materiais-prontos-videos/tipo=VIDEOS/:segmentos/:subsegmentos`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/materiais-prontos-videos/tipo=VIDEOS/:segmentos/:subsegmentos/:produtos`,
    exact: true,
    component: Artefatos,
    private: true
  }
];

const MateriaisProntosLaminas = [
  {
    path: `/materiais-prontos-laminas/tipo=LAMINAS`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/materiais-prontos-laminas/tipo=LAMINAS/:segmento`,
    exact: true,
    component: Subsegmentos,
    private: true
  },
  {
    path: `/materiais-prontos-laminas/tipo=LAMINAS/:segmentos/:subsegmentos`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/materiais-prontos-laminas/tipo=LAMINAS/:segmentos/:subsegmentos/:produtos`,
    exact: true,
    component: Artefatos,
    private: true
  }
];

const MateriaisProntosFolheteria = [
  {
    path: `/materiais-prontos-folheteria/tipo=FOLHETERIA`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/materiais-prontos-folheteria/tipo=FOLHETERIA/:segmento`,
    exact: true,
    component: Subsegmentos,
    private: true
  },
  {
    path: `/materiais-prontos-folheteria/tipo=FOLHETERIA/:segmentos/:subsegmentos`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/materiais-prontos-folheteria/tipo=FOLHETERIA/:segmentos/:subsegmentos/:produtos`,
    exact: true,
    component: Artefatos,
    private: true
  }
];

const MateriaisProntos = [
  {
    path: `/materiais-prontos`,
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: `/materiais-prontos-redes-sociais`,
    exact: true,
    component: Formatos,
    private: true
  },
  {
    path: `/materiais-prontos-redes-sociais/:formato/`,
    exact: true,
    component: Segmentos,
    private: true
  },
  {
    path: `/materiais-prontos-redes-sociais/:formatos/:segmento`,
    exact: true,
    component: Subsegmentos,
    private: true
  },
  {
    path: `/materiais-prontos-redes-sociais/:formatos/:segmentos/:subsegmentos`,
    exact: true,
    component: Produtos,
    private: true
  },
  {
    path: `/materiais-prontos-redes-sociais/:formatos/:segmentos/:subsegmentos/:produtos`,
    exact: true,
    component: Artefatos,
    private: true
  },
  ...MateriaisProntosFolheteria,
  ...MateriaisProntosLaminas,
  ...MateriaisProntosVideos
];

export default MateriaisProntos;
