import React from "react";
const Inicio = React.lazy(() => import("../Inicio"));
const PecasDoSegmento = React.lazy(() => import("../PecasDoSegmento"));

const Contador = [
  {
    path: ["/", "/admin/contador"],
    exact: true,
    component: Inicio,
    private: true
  },
  {
    path: ["/", "/admin/pecas-do-segmento"],
    exact: true,
    component: PecasDoSegmento,
    private: true
  },
];

export default Contador;
