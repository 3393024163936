import React from "react";
import * as E from "./estilos/Estilos";
import { mapaImagens } from "../ativos/imagens/MapaImagens";

const Carregador = () => {
  return (
    <E.Carregador classname="loading">
      <img src={mapaImagens.iconeCarregando} alt="" />
    </E.Carregador>
  );
};

export default Carregador;
