import React from 'react';

const Lixeira = [
    {
        path: `/admin/lixeira`,
        exact: true,
        component: React.lazy(() =>
            import("../Inicio")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/artefatos`,
        exact: true,
        component: React.lazy(() =>
            import("../Artefatos")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/segmentacao`,
        exact: true,
        component: React.lazy(() =>
            import("../Segmentacao")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/lista-segmentacao`,
        exact: true,
        component: React.lazy(() =>
            import("../ListaSegmentacao")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/lista-subsegmentacao`,
        exact: true,
        component: React.lazy(() =>
            import("../ListaSubSegmentacao")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/lista-subsegmentacao/itens`,
        exact: true,
        component: React.lazy(() =>
            import("../ListaSubSegmentacaoItens")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/planos`,
        exact: true,
        component: React.lazy(() =>
            import("../Produtos")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/planos-na-lixeira`,
        exact: true,
        component: React.lazy(() =>
            import("../Planos")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/materiais`,
        exact: true,
        component: React.lazy(() =>
            import("../Materiais")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/tipo`,
        exact: true,
        component: React.lazy(() =>
            import("../TiposMaterial")
        ),
        private: true
    },
    {
        path: `/admin/lixeira/formatos`,
        exact: true,
        component: React.lazy(() =>
            import("../Formatos")
        ),
        private: true
    }
]



export default Lixeira