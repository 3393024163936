const Encerrar = async (redirect = "/login") => {
    const keysToRemove = [
      "_dsat",
      "ds-user-t",
      "idPlano",
      "etapaAtual",
      "tipo",
      "imagemId",
      "produtoSelecionado",
      "ds-login-ts",
      "ds-optin",
      "ds-lgpd",
      "_ads",
      "_utds",
      "ds-login-g",
      "ds-login-c",
      "ds-login-u",
      "ds-login-p",
      "ds-cookies-consent"
    ];

    await Promise.all(keysToRemove.map(key => sessionStorage.removeItem(key)));

    window.location.href = redirect;
    return false;
};

export default Encerrar;
