import React from "react";

const Componente = React.lazy(() => import("../Acesso"));
const Acesso = [
  {
    path: `/login/:userType`,
    exact: false,
    component: Componente,
    private: false
  }
];

export default Acesso;
