import React from "react";
const Componente = React.lazy(() => import("../Inicio"));

const Inicio = [
  {
    path: `/`,
    exact: true,
    component: Componente,
    private: true
  }
];

export default Inicio;
